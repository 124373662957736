body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fancyBackground {
    background: linear-gradient(to right, #97A94D, #B2C561);
}

/* Homepage */
.serviceCategoryDescriptionOdd {
    background: linear-gradient(to right, #97A94D, #B2C561);
}

.serviceCategoryDescriptionEven {
    background: linear-gradient(to right, #B2C561, #97A94D);
}

.linkAvatar {
    background-color: rgba(244, 244, 244, 0.3) !important;
    border: 2px solid white !important;
    color: white;
    width: 70px !important;
    height: 70px !important;
    position: absolute !important;
    top: calc(50% - 35px) !important;
    left: calc(50% - 35px) !important;
    transform: rotateY(0deg) rotate(45deg) !important;
    z-index: 3 !important;
}

.linkAvatar:hover {
    background-color: rgba(244, 244, 244, 0.7) !important;
    border: 3px solid white !important;
    cursor: pointer;
    width: 80px !important;
    height: 80px !important;
    transition: linear 200ms;
    top: calc(50% - 40px) !important;
    left: calc(50% - 40px) !important;
}

.linkAvatar:hover .serviceCategoryLinkIcon {
    font-size: 50px !important;
    transition: linear 200ms;
}

.serviceCategoryLinkIcon {
    font-size: 40px !important;
}

.homepageServiceImage {
    overflow: hidden !important;
}

.homepageServiceImage:hover {
    opacity: 0.9 !important;
    transform: scale(1.05) rotateY(0deg) rotate(1deg) !important;
    overflow: hidden !important;
    transition: linear 200ms;
}

/* Homepage */


/* Sign In */
.paper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.avatar {
    background: #ADBF5D;
}

.form {
    width: 100%;
}

.submit {
    background: #ADBF5D;
}

/* General */
.menuItemActive {
    background: linear-gradient(270deg, rgba(151, 169, 77, 1) 55%, rgba(255, 255, 255, 1) 100%) !important;
    border: 1px solid rgba(151,169,77,1) !important;
}

/* Menu Button */
.iconButtonMenuItemStack:hover {
    background: #f3f3f3 !important;
}

.iconButtonMenuItem {
    width: 100% !important;
}

.iconButtonMenuItem:hover {
    background: none !important;
}

/* Energy Classes */
.energy-class {
    font-weight: 700;
    position: relative;
    /*max-width: 100%;*/
}

.energy-class div {
    display: block;
    position: absolute;
    left: 110%;
    background: #000;
    width: 30px;
    height: 18px;
}

.energy-class div:before {
    content: '';
    position: absolute;
    top: 0;
    right: 100%;
    width: 0;
    height: 0;
    border-top: 9px solid transparent;
    border-right: 9px solid #000;
    border-bottom: 9px solid transparent;
}
.energy-class div:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    line-height: 30px;
    text-align: center;
    color: #fff;
    font-size: 30px;
    text-transform: uppercase;
}

.energy-class div.classAPlusPlusSelected {
    top: 2px;
}

.energy-class div.classAPlusSelected {
    top: 22px;
}

.energy-class div.classASelected {
    top: 42px;
    /*top: 2px;*/
}

.energy-class div.classBSelected {
    top: 62px;
    /*top: 22px;*/
}

.energy-class div.classCSelected {
    top: 82px;
    /*top: 42px;*/
}

.energy-class div.classDSelected {
    top: 102px;
    /*top: 62px;*/
}

.energy-class div.classESelected {
    top: 122px;
    /*top: 82px;*/
}

.energy-class div:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    line-height: 18px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
}
.energy-class div.classAPlusPlusSelected:after {
    content: 'A++';
}
.energy-class div.classAPlusSelected:after {
    content: ' A+ ';
}
.energy-class div.classASelected:after {
    content: ' A ';
}
.energy-class div.classBSelected:after {
    content: ' B ';
}
.energy-class div.classCSelected:after {
    content: ' C ';
}
.energy-class div.classDSelected:after {
    content: ' D ';
}
.energy-class div.classESelected:after {
    content: ' E ';
}

.classAPlusPlus, .classAPlus, .classA, .classB, .classC, .classD, .classE {
    position: relative;
    display: block;
    height: 18px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-left: 3px;
    line-height: 18px;
    color: #fff;
    /*font-family: Arial, sans-serif;*/
    font-size: 14px;
}
.classAPlusPlus::after, .classAPlus::after, .classA::after, .classB::after, .classC::after, .classD::after, .classE::after {
    content: "";
    position: absolute;
    left: 100%;
    border: 9px solid transparent;
    border-left: 9px solid #fbb900;
}
.classAPlusPlus > sup, .classAPlus > sup, .classA > sup, .classB > sup, .classC > sup, .classD > sup, .classE > sup {
    position: absolute;
    top: -3px;
}
.classAPlusPlus {
    width: 30px;
    background-color: #63aa5a;
    border-color: #63aa5a;
}
.classAPlusPlus::after {
    border-left-color: #63aa5a;
}
.classAPlus {
    width: 37px;
    background-color: #7bae4a;
    border-color: #7bae4a;
}
.classAPlus::after {
    border-left-color: #7bae4a;
}
.classA {
    width: 44px;
    background-color: #bdd342;
    border-color: #bdd342;
}
.classA::after {
    border-left-color: #bdd342;
}
.classB {
    width: 51px;
    background-color: #ffe731;
    border-color: #ffe731;
}
.classB::after {
    border-left-color: #ffe731;
}
.classC {
    width: 58px;
    background-color: #fbb900;
    border-color: #fbb900;
}
.classC::after {
    border-left-color: #fbb900;
}
.classD {
    width: 65px;
    background-color: #fb8800;
    border-color: #fb8800;
}
.classD::after {
    border-left-color: #fb8800;
}
.classE {
    width: 72px;
    background-color: #e30613;
    border-color: #e30613;
}
.classE::after {
    border-left-color: #e30613;
}

.breadcrumbLink {
    text-decoration: none;
    font-size: 20px;
    color: inherit;
}

.breadcrumbLink:hover {
    text-decoration: underline !important;
}

.MuiFormControl-root.language .MuiInputLabel-root {
    color: white !important;
}

.chart-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 380px; /* Set the desired height */
    overflow: hidden; /* Hide any content that overflows the container */
}

.chart-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the chart stays within the container */
    height: 350px; /* Ensure the chart stays within the container */
}