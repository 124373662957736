.leaflet-ruler {
    height: 35px;
    width: 35px;
    /*background-image: url("../dist/icon.png"); !* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> *!*/
    background-repeat: no-repeat;
    background-position: center;
}

.leaflet-ruler:hover {
    /*background-image: url("../dist/icon-colored.png"); !* <div>Icons made by <a href="http://www.freepik.com" title="Freepik">Freepik</a> from <a href="http://www.flaticon.com" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a></div> *!*/
}

.leaflet-ruler-clicked {
    height: 35px;
    width: 35px;
    background-repeat: no-repeat;
    background-position: center;
    /*background-image: url("../dist/icon-colored.png");*/
    border-color: chartreuse !important;
}

.leaflet-bar {
    background-color: #ffffff;
}

.leaflet-control {
    cursor: pointer;
}

.result-tooltip {
    background-color: white;
    border-width: medium;
    border-color: #adc05d !important;
    font-size: smaller;
}

.moving-tooltip {
    background-color: rgba(255, 255, 255, 0.7);
    background-clip: padding-box;
    opacity: 0.5;
    border: dotted;
    border-color: #adc05d !important;
    font-size: larger;
}

.plus-length {
    padding-left: 45px;
}
